import { SimpleGrid, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { useItemNumberProducts } from '../providers/ItemNumberProductsProvider';
import { Link } from 'react-router-dom';

export default function InStock() {

    const { data, thisUserInStockData } = useItemNumberProducts();

    var valueNotZero = [];

    /* eslint-disable no-loop-func */
    for (var i of data) {
        if (thisUserInStockData
            .filter((element) =>
                element['PTypM_id'].toString() == i['id'].toString())
            .length > 0) {
            valueNotZero.push(i);
        }
    }

    valueNotZero = valueNotZero.sort((a, b) => a['name'].localeCompare(b['name']));

    return (
        <SimpleGrid columns={[4, 4, 6, 8]} spacing='4' p='2' my='2'>
            {valueNotZero.map((value) => (
                <Box
                    as={Link}
                    to={'/products'}
                    state={{
                        id: value['id'],
                        name: value['name'],
                        data: thisUserInStockData.filter((element) => element['PTypM_id'].toString() == value['id'].toString()),
                        length: thisUserInStockData.filter((element) => element['PTypM_id'].toString() == value['id'].toString()).length,
                    }}
                    aspectRatio={1 / 1}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    key={value['id']}
                >
                    <Box
                        p='2'
                        bg='primary.800'
                        rounded='50%'
                        aspectRatio={1 / 1}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        mb='1'
                    >
                        <Text
                            fontWeight='800'
                            color='#fefefe'
                            fontSize='2xl'
                        >{thisUserInStockData
                            .filter((element) =>
                                element['PTypM_id'].toString() == value['id'].toString())
                            .length}
                        </Text>
                    </Box>
                    <Text
                        fontWeight='800'
                        fontSize='xs'
                        textAlign='center'
                    >
                        {value['name']}
                    </Text>
                </Box>
            ))}
        </SimpleGrid>
    )
}
