import React from 'react'
import {
    Box,
    HStack,
    Flex,
    VStack,
    Text,
    useBreakpointValue,
    IconButton,
} from '@chakra-ui/react'
import { ToastContainer } from 'react-toastify';
import { FaDatabase, FaQrcode } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useCart } from '../../providers/CartProvider';
import SideBar from './SideBar';
import 'react-toastify/dist/ReactToastify.css'; 

export default function Navbar() { 
    const { cartItems } = useCart();
    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    return (
        <>
            <Box
                bg='primary.800'
                px={3}
                w='100%'
                h='8.5vh'
            >
                <Flex h='100%' alignItems={'center'} justifyContent={'space-between'}>

                    {sideBarVariant === 'drawer' && (
                        <SideBar />
                    )}

                    <Flex
                        w='100%'
                        bg='primary.800'
                    >
                        <VStack justifyContent='center' w='100%' alignItems='center' spacing='0'>
                            <Link to='/'>
                                <img src='/logo-white.png' style={{ width: '126px' }} alt='Logo' />
                            </Link>
                         </VStack>


                        <HStack
                            justifyContent='center'
                            alignItems='center'
                            spacing='0'
                        >
                            <IconButton
                                as={Link}
                                to='/cart'
                                icon={<FaDatabase color='white' />}
                                variant='ghost'
                                _hover={{
                                    bg: 'primary.800'
                                }}
                                bg='primary.800'
                            />
                            <Box
                                position='relative'
                                left='-12px'
                                bottom='12px'
                                display='inline-block'
                                width='18px'
                                height='18px'
                                borderRadius='50%'
                                bg='white'
                                p='1px'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <Text
                                    textAlign='center' 
                                    fontSize='sm' 
                                    color='primary.800'
                                    fontWeight='bold' 
                                >{cartItems.length}</Text>
                            </Box>

                            <IconButton
                                as={Link}
                                to='/scan'
                                variant='ghost'
                                _hover={{
                                    bg: 'primary.800'
                                }}
                                bg='primary.800'
                                icon={<FaQrcode color='white' />}
                            />
                        </HStack>

                    </Flex>
                </Flex>

            </Box >
            <ToastContainer position='bottom-center' />
        </>
    )
} 