import axios from "axios";
import { createContext, useContext, useMemo, useState } from "react";
import { url } from "../config"; 

const SearchItemContext = createContext();

const SearchItemProvider = ({ children }) => {

    const [data, setData] = useState({});
    const [materialData, setMaterialData] = useState([]);
    const [amount, setAmount] = useState({}); 
    const [loaded, setLoaded] = useState(false); 
    const [notExists, setNotExists] = useState(false);
    const [searched, setSearched] = useState(true);
    const [searching, setSearching] = useState(false);
    const [found, setFound] = useState(false);

    async function search(itemNumber) {
        console.log('get EComService called');
        setLoaded(true);
        setSearched(true);
        setSearching(true);
        setNotExists(false);
        setData({});
        setMaterialData([]);

        try {
            var { data } = await axios.post(`${url}/api`,
                {
                    "BgmNo": itemNumber,
                    "PGrp_Id": "5",
                    "url": "GetBagDetails"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            console.log(data);

            if (!data.response1.length || !data.response2.length) {
                setNotExists(true);
                setLoaded(true);
                setFound(true);
                setSearched(true);
                return;
            }

            setFound(true);
            setSearching(false);
            setSearched(true);
            setData(data.response1[0]);

            data.response3.forEach(item => {
                if (item.BgmM_BgmId === data.response1[0].BgM_Id) {
                    setMaterialData(prev => [...prev, item]);
                }
            });

            data.response2.forEach(item => {
                if (item.bgm_no === data.response1[0].Item_No) {
                    setAmount(item);
                }
            });

            setLoaded(true);

        } catch (error) {
            return "err";
        } finally {
            setLoaded(false);
        }
    }

    const contextValue = useMemo(
        () => ({
            loaded,
            data,
            materialData,
            amount,
            notExists,
            searched,
            searching,
            search,
            found,
        }),
        [data, loaded] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <SearchItemContext.Provider value={contextValue}>{children}</SearchItemContext.Provider>
    );
};

export const useSearchItem = () => {
    return useContext(SearchItemContext);
};

export default SearchItemProvider;