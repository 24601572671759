import React from "react"
import {
    Button,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    useDisclosure
} from "@chakra-ui/react"
import SideBarLinks from "./SideBarLinks"
import { GiHamburgerMenu } from "react-icons/gi"

export default function SideBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    return (
        <>
            <Button ref={btnRef} colorScheme='black' onClick={onOpen}>
                <GiHamburgerMenu size='22' />
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <SideBarLinks onClick={onClose} />
                </DrawerContent>
            </Drawer>
        </>
    )
}