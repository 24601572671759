import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const ItemNumberProductsContext = createContext();

const ItemNumberProductsProvider = ({ children }) => {

    const { user } = useAuth();
    // const data = [];
    const [data, setData] = useState([]);

    const [thisUserData, setThisUserData] = useState([]);
    const [thisUserNetSaleData, setThisUserNetSaleData] = useState([]);
    const [thisUserInStockData, setThisUserInStockData] = useState([]);

    const [totalData, setTotalData] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);

    const [loading, setLoading] = useState(false);

    async function get() {
        console.log('get EComService called'); 
        setLoading(true);

        try {
            var { data } = await axios.post(`${url}/api`,
                {
                    "PrdDate": "01-01-2000",
                    "url": "EComService_GetItemDetail"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            console.log(data);

            let tempData = [];
            for (let d of data['response4']) {
                tempData.push({
                    'name': d['Category'],
                    'qty': d['Qty'],
                    'id': d['PTypM_id'],
                });
            }

            let tempTotalData = data['response1'];
            // let thisUserId = 'someUserId'; // Define thisUserId appropriately

            console.log(user);
            let tmpThisUserData = tempTotalData.filter(t => t['Bgm_LegMId'].toString() === user['Usr_id'].toString());

            let tempThisUserNetSaleData = tmpThisUserData.filter(t => t['Bgm_PurposeId'] > 0);

            let tempThisUserInStockData = tmpThisUserData.filter(t => t['Bgm_PurposeId'] === 0 || t['Bgm_PurposeId'] === null);

            let tempDropdownData = data['response5'];

            
            console.log(tempTotalData);
            console.log(tempThisUserNetSaleData);
            console.log(tempThisUserInStockData);
            
            setDropdownData(tempDropdownData);
            setData(tempData);
            setTotalData(tempTotalData);
            setThisUserData(tmpThisUserData);
            setThisUserNetSaleData(tempThisUserNetSaleData);
            setThisUserInStockData(tempThisUserInStockData);

        } catch (error) {
            return "err";
        } finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        get();
    }, []);
 
    const contextValue = useMemo(
        () => ({
            loading,
            data,
            totalData,
            thisUserData,
            thisUserNetSaleData,
            thisUserInStockData,
            dropdownData,
        }),
        [data, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <ItemNumberProductsContext.Provider value={contextValue}>{children}</ItemNumberProductsContext.Provider>
    );
};

export const useItemNumberProducts = () => {
    return useContext(ItemNumberProductsContext);
};

export default ItemNumberProductsProvider;