import React, { useRef } from 'react'
import { Box, Text, VStack, Button, HStack } from '@chakra-ui/react'
import { useCart } from '../providers/CartProvider';
import { image } from '../config';
import { FaTrash } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';

export default function CartPage() {
    const { cartItems, deleteItem } = useCart();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const componentRef = useRef();

    return (
        <> 
            <Button
                my='2'
                onClick={handlePrint}
                colorScheme='purple'
            >
                Export
            </Button>

            <Box
                position={'absolute'}
                zIndex={'-100'}
                py='1' 
                bg='white'
                ref={componentRef}
                shadow='md'
                // border='2px solid #6a355b'
                style={{ height: 'auto', width: '80mm', aspectRatio: '1.41/1', fontSize: 'x-small' }}
            >
                <HStack justifyContent='center'>
                    <Text as='h1'>INVOICE/MEMO</Text>
                </HStack>

                <table>
                    <thead>
                        <tr>
                            <th style={{ fontSize: '6px', width: '6px' }}>No.</th>
                            <th style={{ fontSize: '6px', width: '20px' }}>Image</th>
                            <th style={{ fontSize: '6px', width: '100%' }}>Description</th>
                            <th style={{ width: '6px' }}>
                                <Text fontSize='6px'>
                                    Amount
                                </Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems.map((item) => (
                            <tr key={cartItems.indexOf(item)}>
                                <td> <Text fontSize='4px'> {cartItems.indexOf(item) + 1}</Text></td>
                                <td><img height='20px' width='20px' src={`${image}/${item.stleNO}.jpg`} alt='Item' /></td>
                                <td>
                                    <VStack spacing='0' w='100%'>
                                        <HStack spacing='0' w='100%' justifyContent='space-between'>
                                            <Text fontSize='4px'>
                                                Style # : {item.stleNO}
                                            </Text>
                                            <Text fontSize='4px'>
                                                Gr Wt : {item.Product_Weight}
                                            </Text>
                                            <Text fontSize='4px'>
                                                Dia :   {item.diapcs} / {item.diawt}
                                            </Text>
                                        </HStack>
                                        <HStack spacing='0' w='100%' justifyContent='space-between'>
                                            <Text fontSize='4px'>
                                                Item # : {item.Item_No}
                                            </Text>
                                            <Text fontSize='4px'>
                                                Net Wt : {item.Metal_Weight}
                                            </Text>
                                            <Text fontSize='4px'>
                                                Cs :   {item.cspc} / {item.cswt}
                                            </Text>
                                        </HStack>
                                        <HStack spacing='0' w='100%' justifyContent='space-between'>
                                            <Text fontSize='4px'>
                                                Metal : {item.Metal_Purity}
                                            </Text>
                                            <Text fontSize='4px'>
                                                Qty : {item.Quantity}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='4px'>
                                            {item.amount}
                                        </Text>
                                    </VStack>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </Box>

            <Box
                overflowX='scroll'
                w={['100%', '100%', '50%', '30%']}
            >
                <table>
                    <thead>
                        <tr>
                            <th>Img</th>
                            <th>
                                <VStack spacing='0'>
                                    <Text spacing='0'>
                                        Style #
                                    </Text>
                                    <Text spacing='0'>
                                        Item #
                                    </Text>
                                </VStack>
                            </th>
                            <th>
                                <VStack spacing='0'>
                                    <Text fontSize='sm'>
                                        Gr wt
                                    </Text>
                                    <Text fontSize='sm'>
                                        Net wt
                                    </Text>
                                </VStack>
                            </th>
                            <th>
                                <VStack spacing='0'>
                                    <Text fontSize='sm'>
                                        Dia wt
                                    </Text>
                                    <Text fontSize='sm'>
                                        Cs wt
                                    </Text>
                                </VStack>
                            </th>
                            <th>
                                <VStack spacing='0'>
                                    <Text fontSize='sm'>
                                        Amount
                                    </Text>
                                    {/* <Text fontSize='sm'>
                                        Qty
                                    </Text> */}
                                </VStack>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems.map((item) => (
                            <tr key={cartItems.indexOf(item)}>
                                <td><img height='60px' width='60px' src={`${image}/${item.stleNO}.jpg`} alt={`${item.stleNO}`} /></td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='11px'>
                                            {item.stleNO}
                                        </Text>
                                        <Text fontSize='11px'>
                                            {item.Item_No}
                                        </Text>
                                    </VStack>
                                </td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='11px'>
                                            {item.Product_Weight}
                                        </Text>
                                        <Text fontSize='11px'>
                                            {item.Metal_Weight}
                                        </Text>
                                    </VStack>
                                </td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='11px'>
                                            {item.diawt}
                                        </Text>
                                        <Text fontSize='11px'>
                                            {item.Metal_Weight}
                                        </Text>
                                    </VStack>
                                </td>
                                <td>
                                    <VStack spacing='0'>
                                        <Text fontSize='sm'>
                                            {item.amount}
                                        </Text>
                                    </VStack>
                                </td>
                                {/* <td>{JSON.stringify(item)}</td> */}
                                <td>
                                    <button onClick={() => deleteItem(item.Item_No)}>
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </>
    )

}
