import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from "@chakra-ui/react"
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'md',
      },
    },
  },
  colors: {
    "primary": {
      "50": "#f9f7f8",
      "100": "#e6dee4",
      "200": "#d1c1cd",
      "300": "#b89fb1",
      "400": "#aa8ba1",
      "500": "#97728d",
      "600": "#875c7b",
      "700": "#754468",
      "800": "#6a355b",
      "900": "#522145"
    }
  },
  styles: {
    global: {
      'html, body': {
        bg: '#efefef',
      },
    },
  },
})

// removing logs in prod 
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);


reportWebVitals();
