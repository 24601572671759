import {
    useBreakpointValue,
    CircularProgress,
    VStack,
    Center,
    HStack,
    Box
} from '@chakra-ui/react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import SideBarLinks from '../common/SideBarLinks';
import Navbar from '../common/Navbar';
import { useAuth } from '../../providers/AuthProvider';
import ItemNumberProductsProvider from '../../providers/ItemNumberProductsProvider';
import SearchItemProvider from '../../providers/SearchItemProvider';
import CartProvider from '../../providers/CartProvider';

export default function PrivateRoutes() {
    const { loading, logged } = useAuth();
    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });


    if (loading) {
        return (
            <VStack height='100vh'>
                <Center>
                    <CircularProgress isIndeterminate color='purple' />
                </Center>
            </VStack>
        )
    }
    return (
        logged ?
            <ItemNumberProductsProvider>
                <SearchItemProvider>
                    <CartProvider>
                        <HStack spacing='0' bg='#fefefe'>
                            {sideBarVariant === 'sidebar' && <SideBarLinks />}
                            <Box h='100vh' w='100%' overflowY='auto'>
                                <VStack bg='#fefefe' spacing='0'>
                                    <Navbar />
                                    <Outlet />
                                </VStack>
                            </Box>
                        </HStack>
                    </CartProvider>
                </SearchItemProvider>
            </ItemNumberProductsProvider>
            : <Navigate to='/login' />
    )
}

