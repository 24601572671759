import {
    Box,
    Center,
    HStack,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { TbCaretRightFilled } from 'react-icons/tb';
import { BiExit } from "react-icons/bi";
import { LuUsers } from "react-icons/lu";
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';


export default function SideBarLinks({ onClick }) {

    const { logout, user } = useAuth();
    const { pathname } = useLocation();

    return (
        <>
            <VStack onClick={onClick} alignItems='start' w='100%' overflowY='auto' h='100vh' maxW={{ base: '100%', md: '250px' }} bg='primary.800'
                color='#fefefe' p='2'
                spacing='2'
            >
                <Center>
                    <VStack alignItems='start' p='3' rounded='lg' m='2'>
                        <img src='/logo-white.png' h='60px' alt='Logo' />
                    </VStack>
                </Center>

                <Text p='3'>Welcome {user['Name']}</Text>

                <Box as={Link} to='/' _hover={{ bg: 'black', shadow: 'md' }} bg={pathname === '/' ? '#353535' : "transparent"} rounded='lg' w='100%' p='3'>
                    <HStack justifyContent='space-between'>
                        <HStack>
                            <LuUsers size='20px' />
                            <Text fontSize='md' fontWeight='600'>Dashboard</Text>
                        </HStack>
                        <TbCaretRightFilled size='20px' />
                    </HStack>
                </Box> 

                <Box as={Link} to={'/scan'} _hover={{ bg: 'black', shadow: 'md' }} bg={pathname === '/scan' ? '#353535' : "transparent"} rounded='lg' w='100%' p='3'>
                    <HStack justifyContent='space-between'>
                        <HStack>
                            <LuUsers size='20px' />
                            <Text fontSize='md' fontWeight='600'>Scan</Text>
                        </HStack>
                        <TbCaretRightFilled size='20px' />
                    </HStack>
                </Box>

                <Box onClick={logout} _hover={{ bg: 'black', shadow: 'md' }} bg={pathname === '/categories' ? '#353535' : "transparent"} rounded='lg' w='100%' p='3'>
                    <HStack justifyContent='space-between'>
                        <HStack> 
                            <Text fontSize='md' fontWeight='600'>Logout</Text>
                        </HStack>
                        <BiExit size='20px' />
                    </HStack>
                </Box>

            </VStack>
        </>
    )
}